@import "base";

.content {
  max-width: 970px;
  min-width: 650px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 10%;
  grid-auto-columns: min-content;
}

.icon-link {
  display: block;
  
  aspect-ratio: 1 / 1;
  border-radius: 15%;
  &.gray-button {
    background-color: #efefef;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  &:hover {
    opacity: 0.5;
  }
  .icon {
    border-radius: 15%;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.info-display {
  text-align: center;
  word-break: break-all;
  .handle {
    margin: 20% 0 3%;
    font-size: 36px;
    font-weight: 500;
  }
  .job {
    font-size: 14px;
  }
  .display {
    margin-top: 15%;
    h4 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
    }
    p {
      margin-top: 10px;
    }
  }
}